import React from "react";
import { Grid } from "~components";

import * as styles from "./404.module.scss";

/** ============================================================================
 * @page
 * 404
 */
const PageNotFound = ({ location }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  let i;

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <section className={styles.container}>
        <Grid>
          <div className={styles.content}>
            <h1 className="h2">PAGE NOT FOUND</h1>
          </div>
        </Grid>
      </section>
    </>
  );
};

export default PageNotFound;
